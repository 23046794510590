import Swiper from 'swiper';
import devideMode from './_deviceJudg';

let devide = devideMode();

// VIDEOタグ　出しわけ
let mainvisualMovies = document.querySelectorAll('.p-top-mainvisual__video');
Array.prototype.forEach.call(mainvisualMovies, (mainvisualMovie) => {
	if(mainvisualMovie.classList.contains('js-video-srcset')){
		movieUrlSet(devide,mainvisualMovie);
	}
});
function movieUrlSet(mode,target){
	for(let i = 0; i < target.querySelectorAll('source').length; i++){
		if(target.querySelectorAll('source')[i].dataset.device == mode){
			let movieUrl = target.querySelectorAll('source')[i].dataset.src;
			target.querySelectorAll('source')[i].setAttribute('src',movieUrl);
			target.querySelectorAll('source')[i].parentNode.load();
		}
	}
};


const mvSwiper = () => {
	const mainvisual__carousel = document.querySelectorAll('.p-top-mainvisual__carousel');
	if(mainvisual__carousel.length){
		let mvSwiperOption = mvSwiperOptionFunc();
		function mvSwiperOptionFunc (){
			const mainvisual_slide = document.querySelectorAll('.p-top-mainvisual__carousel .swiper-slide');
			if (mainvisual_slide.length > 1) {
				let options = {
					lazyLoading: true,
					preloadImages: false,
					watchOverflow: true,
					slidesPerView: 1,
					lazy: {
						loadOnTransitionStart: true,
						loadPrevNext: true,
					},
					effect: 'fade',
					autoplay: {
						delay: 4000,
						disableOnInteraction: false,
					},
					speed: 800,
					loop: true,
					pagination: {
						el: '.swiper-pagination',
						type: 'bullets',
						clickable: true,
					}
				}
				return options;
			}else if(mainvisual_slide.length == 1){
				let options = {
					speed: 600,
					lazyLoading: true,
					preloadImages: false,
					watchOverflow: true,
					slidesPerView: 1,
					lazy: {
						loadOnTransitionStart: true,
						loadPrevNext: true,
					},
					loop: false,
					pagination: false
				}
				if(mainvisual_slide[0].classList.contains('p-top-mainvisual__item--video')){
					mainvisual_slide[0].querySelector('video').loop = true;
					mainvisual_slide[0].querySelector('video').play();
				}
				return options;
			}
		}


		let mainvisual__carousels = new Swiper(mainvisual__carousel, mvSwiperOption);
					mainvisual__carousels.on('transitionEnd', function () {
						activeSlideMovive();
						prevSlideMovive();
					});

					function activeSlideMovive(){
						let activeSlide = document.querySelector('.p-top-mainvisual__item.swiper-slide-active');
						if(activeSlide.classList.contains('p-top-mainvisual__item--video')){
							mainvisual__carousels.autoplay.stop();
							let video = activeSlide.querySelector('video');
							video.play();

							video.addEventListener('ended',()=>{
								video.pause();
								mainvisual__carousels.autoplay.start();
								mainvisual__carousels.slideNext();
							});
						}else{
							mainvisual__carousels.autoplay.start();
						}
					};

					function prevSlideMovive(){
						if(document.querySelectorAll('.p-top-mainvisual__item.swiper-slide-prev')[0].classList.contains('p-top-mainvisual__item--video')){
							document.querySelectorAll('.p-top-mainvisual__item.swiper-slide-prev')[0].querySelector('video').pause();
						}
					};
	}
};




const newArrivalCarousel = document.querySelectorAll('.p-top-section--newArrival .p-productList--spCarousel');
const rankingCarousel = document.querySelectorAll('.p-top-section--ranking .p-productList--spCarousel');
const coordinateCarousel = document.querySelectorAll('.p-top-section--coordinate .p-productList--spCarousel');
const featureCarousel = document.querySelectorAll('.p-top-section--feature .p-feature--spCarousel');
let newArrivalSwiper = undefined;
let rankingSwiper = undefined;
let coordinateSwiper = undefined;
let featureSwiper = undefined;

// Window resize
const mediaQuery = window.matchMedia('(min-width: 1025px)');

const handleMediaQuery = (mediaQuery) => {
	mvSwiper();
  if (mediaQuery.matches) {
		// PC
		if(newArrivalCarousel && newArrivalSwiper != undefined){
			newArrivalSwiper.destroy();
			newArrivalSwiper = undefined;
		}

		if(rankingCarousel && rankingSwiper != undefined){
			rankingSwiper.destroy();
			rankingSwiper = undefined;
		}

		if(coordinateCarousel && coordinateSwiper != undefined){
			coordinateSwiper.destroy();
			coordinateSwiper = undefined;
		}

		if(featureCarousel && featureSwiper != undefined){
			featureSwiper.destroy();
			featureSwiper = undefined;
		}


  } else {
		// SP
		let options = {
			slidesPerView: 1.7,
			spaceBetween: 20,
			freeMode: true,
			freeModeMomentumRatio: 1.7,
			pagination: false,
			freeModeSticky: true,
			loop: false,
		}
		let options02 = {
			slidesPerView: 1.1,
			spaceBetween: 20,
			freeMode: true,
			freeModeMomentumRatio: 1.1,
			pagination: false,
			freeModeSticky: true,
			loop: false,
		}
		if(newArrivalCarousel && newArrivalSwiper == undefined){
			let newArrivalSwiper = new Swiper(newArrivalCarousel,options);
		}
		if(rankingCarousel && rankingSwiper == undefined){
			let rankingSwiper = new Swiper(rankingCarousel,options);
		}
		if(coordinateCarousel && coordinateSwiper == undefined){
			let coordinateSwiper = new Swiper(coordinateCarousel,options);
		}
		if( featureCarousel && featureSwiper == undefined){
			options02.slidesPerView = 1.35;
			let featureSwiper = new Swiper( featureCarousel,options02);
		}
  }
};
mediaQuery.addListener(handleMediaQuery);
handleMediaQuery(mediaQuery);
